import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'

const WorkHistory = styled.div`
  border-top: 1px dotted black;
  padding-top: 1rem;
`

const Span = styled.span`
  color: blue;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Brian Washington" />
    <p>
      Front-end/full-stack developer that learns new technologies and industries
      quickly. I enjoy collaborating and be part of the team and I am also
      capable of working independently and can be relied upon to get things
      done.
    </p>
    <WorkHistory>
      <p>
        <Span>Software Engineer - Realtor.com - July 2018 - May 2020</Span>
        <ul>
          <li>
            Implemented revamped design and user flow to the Agent Connection
            feature to bring a modern design and more efficient experience to
            the user
          </li>
          <li>
            Participated in a collaboration that focused on rewriting a legacy
            monolithic application into more manageable, independent
            microservices
          </li>
          <li>
            Integrated Google One Tap into Doorsteps which led to a substantial
            increase in the number of user accounts created
          </li>
          <li>
            Made contributions to major email overhaul effort as we transitioned
            from Sparkpost to Sendgrid. This effort consisted of implementing
            new email templates, various server-side logic changes, and
            integrating performance tracking libraries
          </li>
        </ul>
      </p>
      <p>
        <Span>
          Software Engineer - Slalom Consulting - June 2017 - June 2018
        </Span>
        <ul>
          <li>
            Created a serverless web application for one of the world's largest
            telecommunications companies. The application led to a large
            increase in employee bandwidth as the application replaced a tedious
            manual process
          </li>
          <li>
            Worked on a dev team within a financial institution and assisted
            them with identifying and implementing better dev practices and
            procedures
          </li>
        </ul>
      </p>
      <p>
        <Span>Software Engineer - Anglicotech - December 2015 - May 2017</Span>
        <ul>
          <li>
            Created a tablet based app for warehouse workers that replaced
            manual inventory management resulting in a large increase in
            productivity and employee satisfaction
          </li>
          <li>
            Implemented functionality to support disconnected client machines,
            allowing customers to work offline without losing any data
          </li>
        </ul>
      </p>
      <p>
        <Span>
          Software Developer - Fidelity Investments - July 2014 - December 2015
        </Span>
        <ul>
          <li>
            Maintained and provided support for Java Web Services (SOAP)
            applications that are accessed by thousands of customers for
            enrolling in health and other employee benefits
          </li>
        </ul>
      </p>
    </WorkHistory>
  </Layout>
)

export default IndexPage
